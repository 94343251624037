<template>
  <div
    class="user-setup-container"
    :style="{
      'min-height': `${$screenHeight}px`,
    }"
  >
    <div class="user-banner">
      <div class="title">账号设置</div>
      <div class="avatar">
        <img :src="this.user && this.user.headimgurl" alt="" />
      </div>
    </div>
    <!-- 操作设置 -->
    <div class="operation-panel">
      <div class="user-setup-main-container">
        <row-info-bar
          @click.native="bar.onClick"
          v-for="(bar, index) in userSetup"
          :key="index"
          :title="bar.title"
          :val="bar.val"
          icon
        ></row-info-bar>
        <div class="line-bt" @click="onLogout">
          <p>退出账号</p>
        </div>
        <div class="line-bt" @click="onDestory">
          <p>注销并删除数据</p>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <router-link
      tag="div"
      class="container__back"
      :to="{
        path: '/home',
      }"
      >返回</router-link
    >
    <div class="modal_show" v-if="updateType">
      <van-form class="update_form" v-if="updateType === 'nickname'" @submit="onNicknameSubmit">
        <!-- 弹窗改昵称 -->
        <div class="form__inner">
          <van-field
            placeholder="输入名字..."
            class="textarea nickname"
            type="textarea"
            v-model="nickname"
          ></van-field>
          <div class="bt__row">
            <van-button class="cancel" @click="onUpdateCancel">取消</van-button>
            <van-button class="save" :loading="requesting">保存</van-button>
          </div>
        </div>
      </van-form>
      <van-form class="update_form bg-white" v-else-if="updateType === 'qrcode'">
        <!-- 弹窗改二维码 -->
        <div class="form__inner upload-qr">
          <div>
            <van-field class="custom-img-input">
              <template #input>
                <wx-upload prefix="avatar" :limit="3" @onChange="onUploadChange"></wx-upload>
              </template>
            </van-field>
            <p class="hint">保存微信二维码</p>
          </div>
        </div>
        <div class="qr-back" @click="onUpdateCancel">返回</div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import rowInfoBar from '@/views/common/_row-info-bar.vue';
import wxUpload from '@/views/common/_upload-bt.vue';
import { removeToken } from '@/libs/token';

export default {
  name: 'page-user-setup',
  components: {
    rowInfoBar,
    wxUpload,
  },
  data() {
    return {
      updateType: '',
      userSetup: [
        {
          title: '设置备注',
          val: '',
          onClick: () => {
            this.updateType = 'nickname';
          },
        },
        // {
        //   title: '设置微信二维码',
        //   val: '',
        //   onClick: () => {
        //     this.updateType = 'qrcode';
        //   },
        // },
      ],
      nickname: '',
      wechatQr: '',
      requesting: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  // mounted() {
  //   console.log(this.user);
  // },
  watch: {
    user: {
      handler(val) {
        if (val) {
          this.userSetup[0].val = val.nickname;
          // this.userSetup[1].val = '未设置';
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapMutations(['UPDATE_USER']),
    onUploadChange() {},
    onUpdateCancel() {
      this.updateType = '';
    },
    onNicknameSubmit() {
      const that = this;
      // if (that.nickname === '') {
      //   that.$notify('请输入名字');
      // } else {

      // }
      that.requesting = true;
      that.$request
        .post('api/user/account/nickname', {
          nickname: that.nickname,
        })
        .then((res) => {
          const {
            data: { user },
          } = res;
          // 更新本地用户信息
          that.UPDATE_USER({
            key: 'nickname',
            val: user.nickname,
          });
          //
          that.onUpdateCancel();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          that.requesting = false;
        });
    },
    logout() {
      removeToken();
      this.$router.replace({
        path: '/login',
      });
    },
    onLogout() {
      this.$dialog
        .confirm({
          title: '',
          message: '退出时光账号?',
        })
        .then(() => {
          this.logout();
        })
        .catch(() => {});
    },
    onDestory() {
      const that = this;
      that.$dialog
        .confirm({
          title: '确认注销？！',
          message: '注销后账号内容一并清空无法恢复！！！',
          confirmButtonText: '确认注销',
        })
        .then(() => {
          that.$request.get('api/user/account/destroy').then((res) => {
            console.log(res);
            this.logout();
          });
        })
        .catch()
        .finally(() => {
          that.requesting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-banner {
  width: 100%;
  height: 400px;
  padding: 20px 32px;
  background-color: #fff;

  .title {
    font-size: 46px;
    line-height: 67px;
  }

  .avatar {
    margin-top: 110px;
    width: 120px;
    height: 120px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.operation-panel {
  margin-top: 20px;

  .row-info-bar-container {
    border-top: 0;
  }

  .line-bt {
    line-height: 104px;
    font-size: 32px;
    text-align: center;
    background-color: #fff;
    color: #c81d25;
  }
}

.update_form {
  width: 100%;
  height: 100%;

  :deep(.van-cell) {
    &::after {
      display: none;
    }
  }

  :deep(.wx-upload-container) {
    justify-content: center;

    &::after {
      width: 0;
    }
  }

  // ::v-deep {
  //   .van-cell {
  //     &::after {
  //       display: none;
  //     }
  //   }

  //   .wx-upload-container {
  //     justify-content: center;

  //     &::after {
  //       width: 0;
  //     }
  //   }
  // }

  &.bg-white {
    background-color: #fff;
  }

  .form__inner {
    position: relative;

    &.upload-qr {
      padding-top: 508px;
    }
  }

  .hint {
    font-size: 32px;
    text-align: center;
    line-height: 46px;
    margin-top: 40px;
  }

  .qr-back {
    position: absolute;
    bottom: 0;
    line-height: 100px;
    font-size: 32px;
    font-weight: bold;
    color: #000;
    text-align: center;
    width: 100%;
    @include saveAreaBottom;
  }
}
</style>
